import {API, graphqlOperation} from '@aws-amplify/api';
import {dispatchPostgresFunction} from '../graphql/queries';

const api = {
    fetchCellLines: async (): Promise<CellLine[]> => {
        try {
            const result: any = await API.graphql(graphqlOperation(dispatchPostgresFunction,
                {input: {function_name: "get_cell_line", function_parameters: JSON.stringify({})}}))
            return JSON.parse(result.data.dispatchPostgresFunction)
        } catch (err) {
            console.error('Error fetching CellLines', err)
            return []
        }
    },
    /** Gets the compounds from postgres and HARD CODED additions of proteasome inhibitors and DMSO */
    fetchCompounds: async (): Promise<RefCompound[]> => {
        try {
            const result: any = await API.graphql(graphqlOperation(dispatchPostgresFunction,
                {
                    input: {
                        function_name: "fetch_ordered_compounds_with_batches",
                        function_parameters: JSON.stringify({})
                    }
                }))
            const rawCompounds = JSON.parse(result.data.dispatchPostgresFunction)
            const refCompounds = rawCompounds.map((element: any) => {
                return {value: element.molecule_name, batches: element.batches_array.map((num: number) => String(num))}
            }) as RefCompound[]

            refCompounds.unshift({value: 'Bortezomib', batches: ['1']})
            const index = refCompounds.findIndex((element: RefCompound) => element.value.startsWith('MRT'))
            refCompounds.splice(index, 0, {value: 'DMSO', batches: ['1']}, {value: 'MLN4924', batches: ['1']})
            return refCompounds
        } catch (err) {
            console.error('Error fetching compounds', err)
            return []
        }
    },
    fetchPossibleUsers: async (): Promise<CddValue[]> => {
        try {
            const response: any = await API.graphql(graphqlOperation(dispatchPostgresFunction,
                {
                    input: {
                        function_name: "get_active_users",
                        function_parameters: JSON.stringify({})
                    }
                }));
            const data = JSON.parse(response.data.dispatchPostgresFunction)
            return data.map((item: any) => {
                return {'value': item.first_name + " " + item.last_name};
            });
        } catch (error) {
            console.error('Failure fetching user data', error);
        }
        return []
    }

}

export default api