import React, {useState, useEffect} from 'react';
import {
    AccountCircle as AccountCircleIcon,
    AddCircle as NewExpIcon,
    FileUpload as UploadIcon
} from '@mui/icons-material';
import {
    Box, Button, Divider, IconButton, Menu, MenuItem, Toolbar, Typography
} from '@mui/material';
import { StyledAppBar } from '../StyledComponents';
import { APPBAR_HEIGHT, FILTERBAR_HEIGHT } from '../../theme/Constants';

import {Auth} from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {CognitoUser} from "@aws-amplify/auth";
import awsmobile from '../../aws-exports';
import ExperimentsPanel from "./ExperimentsPanel";
import FastaUploadModal from "../modals/FastaUploadModal";
import NewExperimentModal from "./NewExperimentModal/NewExperimentModal";
import FeedbackButton from './FeedbackButton';
import {Navigate, NavLink, Route, Routes, useLocation} from 'react-router-dom';

import { DashboardDataCy } from './Dashboard.cy';
import {usernameCleanup} from '../../util/util';
import api from '../../services/apiService';
import { ReferenceContext } from 'src/store/ReferenceContext';
import ExperimentsOverviewPanel from './ExperimentsPanel/ExperimentsOverviewPanel';
import ExperimentDetailsPanel from './ExperimentsPanel/ExperimentDetailsPanel/ExperimentDetailsPanel';
import StatusView from '../LCMS/StatusView';
import available from  '../../store/PXP_project_names.json'
import BarcodeView from '../barcode/BarcodeView';

// Fixup OAuth redirect URLs with our base URL before doing configure
// This replaces the list of multiple redirect supported by the Cognito user pool
// with the URL that we want to be redirected to after login
// @ts-ignore
if ('oauth' in awsmobile && awsmobile?.oauth?.redirectSignIn) {
    let url = new URL(window.location.href);
    url.search = "";
    url.hash = "";
    url.pathname = "/";
    // @ts-ignore
    awsmobile.oauth.redirectSignIn = url.href;
    // @ts-ignore
    awsmobile.oauth.redirectSignOut = url.href;
}

export default function Dashboard() {
    
    const location = useLocation();
    
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
    const [signedOut, setSignedOut] = useState<boolean>(false);
    const [showNewExperiment, setShowNewExperiment] = useState(false);
    const [showFastaUpload, setShowFastaUpload] = useState(false);
    
    
    
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const openAccountMenu = Boolean(anchorEl);
    const handleAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAccountMenu = () => {
        setAnchorEl(null);
    };
    
    const [referenceData, setReferenceData] = useState<References>({cellLines: [],
        projects: [],
        shortUsers: [],
        refCompounds: [],
        userName: null})

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            console.log('auth event: ' + event);
            switch (event) {
                case 'signIn':
                    getUser().then(userData => setCognitoUser(userData as CognitoUser));
                    setSignedOut(false);
                    break;
                case 'cognitoHostedUI':
                    getUser().then(userData => setCognitoUser(userData as CognitoUser));
                    setSignedOut(false);
                    console.log('cognitoHostedUI', data);
                    break;
                case 'signOut':
                    console.log('signOut');
                    break;
                case 'signIn_failure':
                    console.log('signIn_failure', data);
                    break;
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
            }
        });
        getUser().then(userData => {
            setCognitoUser(userData as CognitoUser)
        })
    }, []);

    async function getUser(): Promise<CognitoUser|null>{
        return await Auth.currentAuthenticatedUser()
            .then(userData => userData as CognitoUser)
            .catch(() => {
                console.log('Not signed in');
                setSignedOut(true);
                return null
            });
    }

    const authConfig = Auth.configure(null); // just getting current config back
    const federatedSignIn = !!(authConfig.oauth && authConfig.oauth.domain);

    useEffect(() => {
        if (signedOut &&federatedSignIn) {
            // Check to see if we're in the middle of our redirect flow
            if (!window.location.search.includes('code=')) {
                void Auth.federatedSignIn();
            } else {
                console.debug('Skipping 2nd federated sign in call');
            }
        }
    }, [federatedSignIn, signedOut]);

    useEffect(() => {
        const fetchReferenceData = async (userName: string) => {
            const [users, cellLines, compounds] = await Promise.all([
                api.fetchPossibleUsers(),
                api.fetchCellLines(),
                api.fetchCompounds()
            ])
            const data: References = {
                cellLines: cellLines,
                projects: available.projects,
                shortUsers: users,
                refCompounds: compounds,
                userName: userName
            }
            setReferenceData(data)
        }
        if (cognitoUser && !signedOut) {
            fetchReferenceData(usernameCleanup(cognitoUser?.getUsername()))
        }
    }, [cognitoUser, signedOut]);
    
    
    
    
    return (<>
        <ReferenceContext.Provider value={referenceData}>
        <StyledAppBar position="fixed" color="primary">
            <Toolbar variant="dense" sx={{px: 5}} disableGutters>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ marginRight: 'auto' }}
                >
                    Proteomics Pipeline
                </Typography>
                <Box className="navButtonGroup" ml={1} mr={2} display="flex" flexDirection="row" alignItems="center">
                    <Button className="navButton navButtonGrouped"
                        onClick={() => setShowNewExperiment(true)} 
                        data-cy={DashboardDataCy.navbar.newExperiment}
                        startIcon={<NewExpIcon />}
                    >
                        New Experiment 
                    </Button>
                    <Button
                        className="navButton navButtonGrouped"
                        onClick={() => { setShowFastaUpload(true);}}
                        startIcon={<UploadIcon />}
                    >
                        Upload DB
                    </Button> 
                </Box>
                <Divider light flexItem orientation="vertical" sx={{ m: 1 }} />
                <NavLink to="/experiment/DEG/dashboard"
                    className={location.pathname.endsWith('dashboard') || location.pathname.endsWith('details')
                        ? 'navLink navLinkText active' 
                        : 'navLink navLinkText'
                    }
                >
                    PxP Home
                </NavLink>
                <Divider light flexItem orientation="vertical" sx={{ mx: 0, my: 2 }} />
                <NavLink to="/lcms/status" className="navLink navLinkText">
                    LCMS
                </NavLink>
                <Divider light flexItem orientation="vertical" sx={{ mx: 0, my: 2 }} />
                <NavLink to="barcode" className="navLink navLinkText">
                    Barcode mapper
                </NavLink>
                <Divider light flexItem orientation="vertical" sx={{ m: 1 }} />
                <FeedbackButton
                    url="https://monterosatx.atlassian.net/servicedesk/customer/portal/3/group/7/create/27"
                    buttonColor="inherit"
                    iconFontSize="large"
                    relativePadding={3}
                    relativeMarginX={4}
                    relativeMarginY={0}
                    tooltipTitle="Give Feedback"
                />
                <IconButton color="inherit" title="Account Menu" onClick={handleAccountMenu}>
                    <AccountCircleIcon />
                </IconButton>
                <Menu id="account-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openAccountMenu}
                    onClose={handleCloseAccountMenu}
                >
                    <MenuItem color="inherit" onClick={() => {
                        Auth.signOut()
                            .then(() => {
                                window.location.href = "/"
                            })
                            .catch(err => console.log(err))
                    }}>
                        Logout {referenceData.userName}
                    </MenuItem>
                </Menu>
            </Toolbar>
        </StyledAppBar>
        <Box component="main"
            height={`calc(100vh - (${APPBAR_HEIGHT} + ${FILTERBAR_HEIGHT}))`}
            mt={location.pathname.endsWith('barcode') ? `calc(${APPBAR_HEIGHT})` : `calc(${APPBAR_HEIGHT} + ${FILTERBAR_HEIGHT})`}
            p={1}
            sx={{ overflowY: 'auto' }}
        >
            <Routes>
                <Route path="/experiment" element={<ExperimentsPanel />}>
                    <Route path=":experimentCode/dashboard"
                           element={<ExperimentsOverviewPanel/>} />
                    <Route path=":experimentCode/details"
                           element={<ExperimentDetailsPanel/>} />
                </Route>

                <Route path="*"
                       element={<Navigate to="/experiment/DEG/dashboard" replace/>} />

                <Route path="/lcms/status"
                   element={<StatusView />} />
                <Route path="barcode"
                   element={<BarcodeView />} />
            </Routes>
        </Box>
        <NewExperimentModal open={showNewExperiment} handleClose={() => setShowNewExperiment(false)} />
        {showFastaUpload && <FastaUploadModal open={showFastaUpload} handleClose={() => setShowFastaUpload(false)}/>}
        </ReferenceContext.Provider>
    </>);
}